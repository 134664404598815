<template>
  <v-container v-if="pageLoading" class="bg-color-theme px-3">
    <v-skeleton-loader type="list-item-two-line, card"></v-skeleton-loader>
  </v-container>

  <v-container v-else class="bg-color-theme px-3 mailing-list-main">
    <p class="text-67 font-14px">
      日報の編集が行われた際、メールで通知します。
    </p>

    <div class="text-right">
      <v-btn
        text
        :to="{ name: 'addSupplierMail' }"
        right
        class="primary--text mb-1"
        >＋ 追加する</v-btn
      >
    </div>
    <v-row>
      <v-col cols="12" md="12">
        <v-list dense color="transparent" class="details-list">
          <v-list-item-group>
            <v-list-item
              v-for="item in getMailingLists"
              :key="item.id"
              class="item-list item-active"
              :to="{ name: 'editSupplierMail', params: { id: item.id } }"
            >
              <v-list-item-content>
                <v-list-item-title class="font-15px font-weight-regular">
                  {{ item.name }}
                  <p class="font-12px mb-0">
                    {{ item.email_address }}
                  </p>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon size="15" color="white">$rightArrow</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MailingList",
  data() {
    return {
      pageLoading: false,
      syncing: "0"
    };
  },
  created() {
    let this_this = this;
    this.$root.$off("shopChanged");
    this.$root.$on("shopChanged", function() {
      this_this.getDataFromApi();
    });
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["getMailingLists"])
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      let param = {
        shop_id: this.$store.getters.getFirstShopObject.id
      };

      this.$store.dispatch("MAILINGLIST_GET_ALL", param).finally(() => {
        this.pageLoading = false;
      });
    }
  }
};
</script>

<style lang="scss" scope src="./style.scss"></style>
